$env: 1;
@import "../../../@core/scss/base/bootstrap-extended/include";
@import "../../../@core/scss/base/components/include";

.signature {
  // border: 2px solid;
  // border-color: $primary;
  // border-radius: 5px;

  width: 50%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

#AuditDetails {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: #261d56;
  }
}

#user-details {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #525866;
  }

  .item-value {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6e6b7b;
  }
}

#medication-details {
  // h2 {
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 39px;
  //   color: #261d56;
  // }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #000000;
  }
}

.pharmacy-available {
  background-color: $menu-dark-bg-color;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 5px;
  &-content {
    background-color: #fafafafa;
    padding: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: $menu-dark-bg-color;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.badge-show {
  // background-color: red;
  padding: 2px 4px;
  color: white;
  border-radius: 7px;
  font-weight: 500;
  font-size: 11px;
}

.dottedBorderTop {
  border-top: 1px dotted black;
}

.risk-complaince-details {
  hr {
    color: #d2d4d8 !important;
  }

  .showmore {
    color: #375dfb;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    span {
      margin-left: 19px;
      margin-right: 29px;
    }
  }

  .title {
    color: #868c98;
    font-size: 14px;
  }
  .value {
    color: #0a0d14;
    font-weight: 500;
    font-size: 16px;
  }

  .timeline {
    list-style: none;
    padding: 0;
    position: relative;
  }

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    left: 20px;
    margin-left: -1.5px;
    border-left: 3px dashed #d4d4d4; /* Change the background to a dashed border */
  }

  .timeline > li {
    margin-bottom: 20px;
    position: relative;
  }

  .timeline > li .timeline-badge {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 0px;
    margin-right: 10px;
    background-color: #f6f8fa;
    border-radius: 50%;
  }

  .timeline > li .timeline-panel {
    position: relative;
    margin-left: 60px;
    padding: 0 20px 0 10px;
    // border-radius: 5px;
    // background: #f5f5f5;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .badge-custom {
    border-radius: 40px !important;
    padding: 4px 15px !important;
    font-weight: 600;
    font-size: 12px;
    max-width: fit-content;
  }

  .badge-minor {
    background-color: #cbf5e5; /* Light Grey */
    color: #176448; /* Black Text */
  }
  .badge-light {
    background-color: #fbdfb1; /* Light Grey */
    color: #693d11; /* Black Text */
  }

  .badge-warning {
    background-color: #ffdac2; /* Warning Yellow */
    color: #6e330c; /* Black Text */
  }

  .badge-danger {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  .badge-catasatrophic {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  /* Additional Custom Badge Classes */

  .badge-secondary {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  .badge-legend-light {
    background-color: #f1f1f1; /* Same as .badge-light for Legends */
    color: #000; /* Black Text */
  }

  .badge-legend-warning {
    background-color: #ffc107; /* Same as .badge-warning for Legends */
    color: #000; /* Black Text */
  }

  .badge-legend-danger {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }
  .badge-legend-catasatrophic {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21; /* White Text */
  }

  .border-rig {
    border-right: 0.5px solid #d2d4d8;
  }
}

.correction-plan-details {
  @media (max-width: 1024px) {
    .row-main {
      display: flex;
      flex-direction: column;

      .col-left,
      .col-right {
        width: 100%; // Make each column take the full width
      }
    }
  }

  .action-button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px !important;
    padding: 7px 13px !important;

    &_green {
      border: 0px solid #e2e4e9 !important;
      background: #008655 !important;
      color: #fff;

      box-shadow: 0px 1px 2px 0px rgba(55, 93, 251, 0.08) !important;
    }

    &_danger {
      border: 1px solid #e2e4e9 !important;
      background: #fff !important;
      color: #525866 !important;
      box-shadow: 0px 1px 2px 0px rgba(82, 88, 102, 0.06) !important;
    }
  }

  .badge-custom {
    border-radius: 10px !important;
    padding: 3px 10px !important;
    font-weight: 600;
    font-size: 13px !important;
    max-width: fit-content;
    margin-top: 3px !important;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-image: none !important;
    background-color: currentColor;
    display: inline-block;
    margin-right: 7px;
  }

  .badge-low {
    background-color: #cbf5e5; /* Light Grey */
    color: #176448 !important;
    border: #176448 1px solid; /* Black Text */
  }
  .badge-medium {
    background-color: #fbdfb1; /* Light Grey */
    color: #693d11 !important;
    border: #693d11 1px solid; /* Black Text */
  }

  .badge-high {
    background-color: #ffdac2; /* Warning Yellow */
    color: #6e330c !important;
    border: #6e330c 1px solid; /* Black Text */
  }

  .badge-critical {
    background-color: #f8c9d2; /* Danger Red */
    color: #710e21 !important;
    border: #710e21 1px solid; /* White Text */
  }

  .dropdown-menu {
    border-radius: 20px;
    overflow: hidden;
    .dropdown-item {
      width: 100%;
    }
  }

  .showmore {
    color: #375dfb;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    span {
      margin-left: 19px;
      margin-right: 29px;
    }
  }

  .timeline {
    list-style: none;
    padding: 0;
    position: relative;
  }

  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    left: 20px;
    margin-left: -1.5px;
    border-left: 3px dashed #d4d4d4; /* Change the background to a dashed border */
  }

  .timeline > li {
    margin-bottom: 20px;
    position: relative;
  }

  .timeline > li .timeline-badge {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 0px;
    margin-right: 10px;
    background-color: #f6f8fa;
    border-radius: 50%;
  }

  .timeline > li .timeline-panel {
    position: relative;
    margin-left: 60px;
    padding: 0 20px 0 10px;
  }

  hr {
    color: #d2d4d8 !important;
  }

  .top-card-head {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0px 15px;
    .plan-title {
      margin: 0;
      color: #0a0d14;
      font-size: 16px;
      font-weight: 500;
      padding-right: 10px;
      border-right: 1px solid #0a0d14;
    }
  }

  .top-card-foot {
    display: flex;

    @media (max-width: 720px) {
      flex-direction: column;
    }
    position: relative;
    &_opened,
    &_in {
      display: none;
    }

    &_over_due {
      background-color: rgb(255, 238, 216);
      position: absolute;
      top: 0;
      right: 10px;
      color: #000;
      padding: 3px 7px;
      font-size: 10px;
    }
    &_rejected {
      background-color: rgb(255, 216, 216);
      position: absolute;
      top: 0;
      right: 10px;
      color: #000;
      padding: 3px 7px;
      font-size: 10px;
    }
    &_approved {
      background-color: rgb(203, 255, 203);
      position: absolute;
      top: 0;
      right: 10px;
      color: #000;
      padding: 3px 7px;
      font-size: 10px;
    }
    .info-card {
      padding: 10px 20px;
      text-align: left;
      .title {
        color: #868c98;
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }
      .value {
        font-size: 16px;
        font-weight: 400;
        color: #0a0d14;
        margin: 0;
      }

      .value-elipse {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .sector {
        color: #375dfb !important;
        border: 1px solid #375dfb;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 14px !important;
        max-width: fit-content;
      }

      .correction-duration {
        color: #868c98;
        border: 1px solid var(--stroke-sub-300, #cdd0d5);
        background: #f6f8fa;
        max-width: fit-content;
        padding: 3px 10px;
        border-radius: 10px;
        margin: 0;
        margin-top: 3px;
        font-size: 10px;
        font-weight: 500;
        svg {
          margin-bottom: 2px;
        }
        span {
          color: #0a0d14 !important;
        }
      }

      @media (min-width: 720px) {
        &:not(:last-child) {
          border-right: 1px solid #d2d4d8;
        }
      }
      @media (max-width: 720px) {
        &:not(:last-child) {
          border-bottom: 1px solid #d2d4d8;
        }
      }
    }
  }

  .plan-activity-card {
    .overall {
      border: 1px solid #d2d4d8;
      border-radius: 10px;
      padding: 10px;

      .percentage {
        font-size: 24px;
        font-weight: 500;
        color: #0a0d14;
        margin: 0;
      }
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #0a0d14;
        margin: 0;
      }
      .progress-container {
        margin-top: 5px;
      }
    }

    .corrected {
      border: 1px solid #d2d4d8;
      border-radius: 10px;
      padding: 10px;

      .discription {
        font-size: 12px;
        font-weight: 400;

        color: #525866;
        margin: 0;
      }
      .title {
        font-size: 14px;
        font-weight: 500;

        color: #0a0d14;
        margin: 0;
      }
      .progress-container {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px;

        .progress {
          width: 100px;
          height: 7px;
        }

        .progress-bar {
          background-color: #38c793;
        }

        .date {
          color: #525866;
          font-size: 11px;
          font-weight: 500;
          margin: 0;
          border: 1px solid #e2e4e9;
          border-radius: 5px;
          padding: 3px 5px;

          span {
            margin-right: 5px;
          }
        }
      }
    }

    .not-corrected {
      border: 1px solid #d2d4d8;
      border-radius: 10px;
      padding: 10px;

      .discription {
        font-size: 12px;
        font-weight: 400;

        color: #525866;
        margin: 0;
      }
      .title {
        font-size: 14px;
        font-weight: 500;

        color: #0a0d14;
        margin: 0;
      }
      .progress-container {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px;

        .progress {
          width: 100px;
          height: 7px;
        }

        .progress-bar {
          background-color: #f17b2c;
        }

        .date {
          color: #525866;
          font-size: 11px;
          font-weight: 500;
          margin: 0;
          border: 1px solid #e2e4e9;
          border-radius: 5px;
          padding: 3px 5px;
        }
      }
    }
  }

  .collapse-content {
    background-color: #f6f8fa;
  }
}

.correction-timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  overflow: hidden;

  .history-item {
    position: relative;
    padding-left: 20px;

    &.parent {
      margin-bottom: 0px;
    }

    &.child {
      margin-left: 10px;
      padding-left: 25px;
      margin-top: -7px;
      // border-left: 2px dotted #ccc;
    }

    .timeline-icon {
      position: absolute;
      left: -10px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
    }
    .timeline-icon-sub {
      position: absolute;
      left: -13px;
      top: 17px;

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
    }

    .timeline-children {
    }

    .timeline-content {
      margin-bottom: 10px;
      padding-top: 20px;

      h3 {
        margin: 0;
        color: #0a0d14;
        font-size: 16px;
        font-weight: 500;
      }

      .breif {
        margin-left: 10px;
        display: flex;
        font-size: 12px;
        color: #525866;
        align-items: center;
        cursor: pointer;
        p {
          margin-bottom: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 90px;
          margin-left: 5px;
        }
      }

      h4 {
        margin: 3px 10px 0px 0px;

        color: #000;
        font-size: 14px;
        font-weight: 400;
      }

      .date-pill {
        border-radius: 6px;
        border: 1px solid #e2e4e9;
        background: #fff;
        width: max-content;
        padding: 3px 10px;
        color: #525866;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-top: 5px;

        .status-dot {
          width: 8px;
          height: 8px;
          background-color: #38c793; /* Green color */
          border-radius: 50%;
          margin-right: 8px;
          margin-top: -2px; /* Space between the dot and the text */
        }
      }
    }

    // Adds the dotted line before each item
    &::before {
      content: "";
      position: absolute;
      left: -1px;
      top: 10px; // Adjust to align with your icon's center
      height: 100%;
      width: 2px;

      border-left: 2px dashed #cdd0d5;
    }
  }
}

.correction-information {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
  margin-top: 15px;
  height: 100%;
  border-left: 1px solid #e2e4e9;
  .info-card {
    border-radius: 6px;
    gap: 5px;

    border: 1px solid #e2e4e9;
    background: #fff;
    color: #525866;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    max-width: max-content;
    padding: 3px 10px;
  }
}

.progress-bar-custom {
  display: flex;
  // gap: 5px; // White space between cells
  justify-content: space-between;
}

.cell {
  width: 7px !important; // Adjust width as needed
  height: 20px !important; // Adjust height as needed

  &.filled {
    background-color: #38c793;
  }

  &.unfilled {
    background-color: #e2e4e9;
  }
}

.actionPlan-model {
  .modal-content {
    max-width: 500px !important;
    border-radius: 20px;
  }
  form {
    padding: 0px 10px;
  }
  .title {
    color: #0a0d14;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
  }

  label {
    color: #0a0d14;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    border-radius: 10px !important;
    background-color: #fff !important;
  }

  .primary-button {
    font-size: 14px;
    font-weight: 500;
    background: #008655 !important;
    box-shadow: 0px 1px 2px 0px #375dfb14;
    color: #fff;
    border-radius: 10px !important;
    padding: 10px 15px !important;
  }
  .outline-button {
    font-size: 14px;
    font-weight: 500;
    background: #fff !important;
    // box-shadow: 0px 1px 2px 0px #375dfb14;
    color: #008556 !important;
    border: 1px solid #008556 !important;
    border-radius: 10px !important;
    padding: 10px 15px !important;
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
  overflow: hidden;

  .chat-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f8f9fa;
    padding: 40px 20px;
    max-height: 500px;

    .message {
      display: flex;
      margin-bottom: 15px;
      position: relative; // Required for pseudo-elements

      p {
        margin: 0 !important;
      }

      &.self {
        justify-content: flex-end;

        .message-bubble {
          background-color: #375dfb;
          color: #fff;
          border-radius: 12px 0px 12px 12px;
          padding: 10px 15px;
          position: relative;
          min-width: 150px;
          max-width: 80%;
          flex-wrap: wrap;

          // Arrow at the top-right
          &::before {
            content: "";
            position: absolute;
            top: 0px;
            right: -10px;
            border-width: 10px 10px 10px 0;
            border-style: solid;
            border-color: #375dfb transparent transparent transparent;
          }
        }
      }

      &.other {
        justify-content: flex-start;

        .message-bubble {
          background-color: #fff;
          color: #0a0d14;
          border-radius: 0px 12px 12px 12px;
          padding: 10px 15px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: -10px;
            border-width: 10px 0 10px 10px;
            border-style: solid;
            border-color: #fff transparent transparent transparent;
          }
        }
      }
    }
  }

  .chat-editor {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: end;
    background-color: #fff;

    .fr-box,
    .fr-basic {
      flex: 1;
      border-radius: 0px !important;
      width: 100% !important;
    }

    .fr-second-toolbar {
      display: none !important;
    }
    .button-container {
      padding: 0px 10px;
      padding-bottom: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .Attachment-sm {
        margin: 0 !important;
      }

      .row {
        margin-top: 10px !important;
      }
    }

    button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
