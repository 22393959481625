$env: 1;
.modal_main {
  .modal-content {
    min-height: calc(100vh - 150px) !important;
    width: 100vw !important;
  }
  .modal_body {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search_box {
        margin-top: 20px;
        display: flex;
        align-items: center;
        border-radius: var(--Large, 6px);
        background: var(--bg-white-0, #fff);

        .dropdown {
          border: 1px solid var(--stroke-soft-200, #e2e4e9);
          padding-block: 8px;
          background: #f6f8fa;
          border-radius: 6px 0px 0px 6px;
          cursor: pointer;
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          .dropdown_icon {
            margin-left: 6px;
          }
          .dropdown_val {
            padding-inline: 8px;
            color: var(--Gray-700, #464f60);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.28px;
          }
          .chevron {
            margin-right: 6px;
          }
        }

        .search_input {
          min-width: 320px;
          padding-right: 30px;
        }

        .searchIcon {
          margin-left: -30px;
          cursor: pointer;
          padding: 6px;
        }

        .filter-date {
          margin-right: 0px;
          position: relative;
          .date-picker-input {
            min-width: 280px !important;
            padding: 10px;
            background: #f4f5f6 !important;
            border-radius: 0px 6px 6px 0px;
            height: 38px;
            border: 1px solid #e2e4e9;
          }

          figure {
            position: absolute;
            top: 9px;
            right: 10px;
          }
        }

        .form-control {
          padding: 0.581rem 1rem;
          margin: -0.5px !important;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
      }

      .plus_btn {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 6px;
        border-radius: var(--radius-8, 8px);
        border: 1px solid var(--stroke-soft-200, #e2e4e9);
        background: var(--bg-white-0, #fff) !important;
        box-shadow: 0px 1px 2px 0px rgba(82, 88, 102, 0.06);
      }

      .cross_icon {
        cursor: pointer;
        width: 60px !important;
      }
    }
    .parent_record {
      div:nth-child(1) {
        max-width: 300px;
      }
      div:nth-child(2) {
        max-width: 150px;
      }
      display: flex;
      padding: 12px 20px;
      justify-content: space-between;
      align-items: center;
      background: var(--bg-weak-100, #f6f8fa);
      color: #1a355e;
      font-feature-settings: "liga" off, "clig" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 10px;

      .date_span {
        color: #1a355e;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .date_child {
        color: #464f60;
        background: #e9edf5;
        padding-inline: 8px;
        padding-bottom: 2px;
        border-radius: 4px;
      }
      .status {
        color: var(--state-success, #38c793);
        background: var(--bg-white-0, #fff);
        border-radius: var(--Large, 6px);
        border: 1px solid var(--stroke-soft-200, #e2e4e9);
        padding: 4px 8px 4px 4px;
        .icon {
          margin-right: 5px;
        }
      }

      .heading {
        margin-bottom: 8px;
        color: var(--text-soft-400, #868c98);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .status {
    background: var(--bg-white-0, #fff);
    border-radius: var(--Large, 6px);
    border: 1px solid var(--stroke-soft-200, #e2e4e9);
    padding: 4px 8px 4px 4px;
    .icon {
      margin-right: 5px;
    }
  }
  .ant-dropdown-menu {
    width: 160px;
    margin-top: -2px;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    padding-left: 15px;
  }

  .dropdown,
  .dropstart {
    position: unset;
  }

  .hr {
    height: 1px;
    margin-block: 10px;
    background: var(--stroke-soft-200, #e2e4e9);
  }
}

.table-cells {
  cursor: pointer;
}
.status_dot {
  padding-right: 4px;
  height: 8px;
  width: 8px;
  margin-inline: 6px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-btn {
  height: 26px;
  border-radius: var(--Medium, 4px);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: calc(100vw - 20vw) !important;
  }
}
