$env: 1;
.custom-list {
  border-radius: 20px 20px 0px 0px;

  .rdt_TableHeadRow {
    // background-color: black;

    padding: 20px 0px;
    // font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
  }
  // .dVgdrY {
  //   overflow: hidden !important;
  // }
  .rdt_Table {
    margin-left: 20px;
  }
}

.custom-data-table .rdt_TableRow:not(:last-child) {
  position: relative;  /* Ensure the row is positioned relative for the pseudo-element */
}

.custom-data-table .rdt_TableRow:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  height: 1px;
  background-color: var(--stroke-soft-200, #E2E4E9)
}



.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  &ul {
    margin-top: 0px !important;
  }

  .pagination-icons {
    .double_chevron_left, .double_chevron_right {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      border-radius: 6px;
    }
    .double_chevron_left:hover, .double_chevron_right:hover{
      background: var(--bg-weak-100, #F6F8FA);
      box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
    }
    .react-paginate {
      margin-top: 1rem !important;
      .previous, .next {
        .page-link {
          background: transparent !important;
          border: none !important;

          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          width: 35px;
          border-radius: 6px;
        }
      }
      .previous:hover, .next:hover {
        .page-link {
          background: var(--bg-weak-100, #F6F8FA) !important;
          box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24) !important;
        }
      }
      .active {
        border-radius: 6px;
      }
    }

    display: flex;
    align-items: center;

    .page-item {
      margin: 0 4px;
      
      &.active .page-link {
        color: var(--text-sub-500, #525866) !important;
        border-radius: 6px !important;
        border: 1px solid var(--stroke-soft-200, #E2E4E9);
        background: var(--bg-weak-100, #F6F8FA);
        box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);

      }

      .page-link {
        color: var(--text-sub-500, #525866);
        text-align: center;
        font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.084px;

        height: 35px;
        width: 35px;
        border-radius: 6px;
        border: 1px solid var(--stroke-soft-200, #E2E4E9);
        background: var(--bg-white-0, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);


        &:hover {
          background: #e2e8f0;
          border-radius: 6px;

        }
      }
    }
  }
}

.pagination_footer {
  .dropdown {
    border: 1px solid red;
    padding-inline: 8px;
    padding-block: 6px;
    border-radius: var(--radius-8, 8px);
    border: 1px solid var(--stroke-soft-200, #E2E4E9);
    background: var(--bg-white-0, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
    .chevron {
      cursor: pointer;
    }
  } 
}

