@mixin appTransition() {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

@mixin fixWidth($width: 100%) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

@mixin appBorderRadius($radius: 8px) {
  border-radius: $radius !important;
  -webkit-border-radius: $radius !important;
  -moz-border-radius: $radius !important;
  -ms-border-radius: $radius !important;
  -o-border-radius: $radius !important;
}
@mixin scrollBarThumbAndTrack {
  &-track {
    border: none;
    background-color: transparent;
  }

  &-thumb {
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: rgba(0, 0, 0, 0.3);
    @include appBorderRadius(100px);
  }
}
@mixin customScrollbar($showOnHover: false) {
  &::-webkit {
    &-scrollbar {
      height: 8px;

      @if $showOnHover {
        height: 0px;
      } @else {
        @include scrollBarThumbAndTrack();
      }
    }
  }
  &:hover {
    &::-webkit {
      &-scrollbar {
        padding-top: 1rem;
        width: 0px;
        @if $showOnHover {
          height: 8px;
          @include scrollBarThumbAndTrack();
        }
      }
    }
  }
}
@mixin paginationButton {
  background: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--gray-300, #d0d5dd);
  @include appBorderRadius();
}

@mixin appFlex(
  $justify: flex-start,
  $align: center,
  $direction: row,
  $wrap: wrap
) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
  flex-direction: $direction;
}

@mixin appTextEllipsis($width: 100%) {
  width: $width;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
