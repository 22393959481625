$env: 1;
// @import "../../../../src/assets/scss/app-mixins.scss";
@import "../../../assets/scss/app-mixins.scss";

.audit-method-select {
  min-width: 150px;
}

.horizontal-hr {
  height: 35px;
  width: 1px;
  background-color: #cdd0d5;
}

.count-text {
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.records-items {
  border-left: 1px solid #ebebeb;
  // background-color: #171b1e;
  // width: 20px;
  height: 40px;
  margin: 0px 10px;
}

.text {
  // font-family: "Poppins";
  // font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* or 114% */
  align-self: center;
  color: #171b1e;
}

.active-tab-correction-Plan {
  border-bottom: 2px solid #006938 !important;

  .requests-list-STCS--tabsContainer__tab--text {
    color: #006938 !important;
  }
}

// .pagination-row-select {
//   display: flex;
//   align-items: center;
//   label {
//     font-size: 14px;
//     margin-right: 10px;
//   }
//   .react-select {
//     width: 80px;
//     .select__control {
//       border-radius: 10px;
//       .select__indicators {
//         width: 32px;
//       }
//     }
//   }
// }
.perPage {
  .select__control {
    width: 100px !important;
  }
}

.pagination-row-select {
  display: flex;
  align-items: center;
  label {
    font-size: 14px;
    margin-right: 10px;
  }
  .react-select {
    width: 70px;
    .select__control {
      border-radius: 10px;
      .select__indicators {
        width: 32px;
      }
    }
  }
}
.pagination-row-search {
  position: relative;
  margin-right: 10px;
  input {
    min-width: 250px !important;

    border: none;
    background: #f4f5f6;
    border-radius: 8px;
    padding: 10px;
  }
  figure {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}

.filter-date {
  margin-right: 10px;
  position: relative;
  .date-picker-input {
    min-width: 250px !important;

    padding: 10px;
    border: none;
    background: #f4f5f6 !important;
    border-radius: 8px;
    height: 41px;
  }

  figure {
    position: absolute;
    top: 9px;
    right: 10px;
  }
}

.list-header {
  padding: 10px 30px;
}

.dashboard-button {
  padding: 0px 10px !important;
  padding-top: 12px !important;
  height: 40px;
  width: 130px;
  color: #11a945;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  border: 1px solid #11a945 !important;
}

.add-form-button {
  color: white;
  padding: 0px 10px !important;
  padding-top: 10px !important;
  width: 160px;
  background-color: #11a945;
  border-radius: 5px;
  height: 40px;
  display: flex;
  margin-left: 10px;
  border: none !important;
  justify-content: space-between;
}
.add-form-button-small {
  color: white;
  padding: 0px 10px !important;
  padding-top: 10px !important;
  width: auto;
  background-color: #11a945;
  border-radius: 5px;
  height: 40px;
  display: flex;
  margin-left: 10px;
  border: none !important;
  justify-content: space-between;
}

.quick-create-form-button {
  color: white;
  padding: 0px 10px !important;
  padding-top: 10px !important;
  width: 160px;
  background-color: #11a945;
  border-radius: 5px;
  height: 30px;
  display: flex;
  margin-left: 10px;
  border: none !important;
  justify-content: space-between;
}
.quick-create-add-form-button-small {
  color: white;
  padding: 0px 10px !important;
  padding-top: 10px !important;
  width: auto;
  background-color: #11a945;
  border-radius: 5px;
  height: 40px;
  display: flex;
  margin-left: 10px;
  border: none !important;
  justify-content: space-between;
}

.task-button {
  color: white;
  padding: 0px 10px !important;
  margin: 0px;
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  p {
    margin: 0px;
  }
}

.task-button-start {
  color: #11a945 !important;
  border: 1px #11a945 solid !important;
  margin-right: 5px !important;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 620px) {
    justify-content: flex-start;
  }
}

.rdt_TableHeadRow {
  // background-color: #f6f8fa;
  background-color: white !important;

  font-weight: 800 !important;
  font-size: 14px !important;
  .rdt_TableCol,
  .rdt_TableCol_Sortable {
    color: #525866 !important;
  }
}

.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: 970px) {
    justify-content: center;
    margin-bottom: 10px;
  }
}

.task-listing {
  margin: 10px;
}
.row-item {
  background-color: white;
  width: "100%";
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 5px;
  padding: 5px;
}

.expand-container {
  max-height: 300px;
  overflow: auto;

  .timeLine {
    // background-color: #11a945;

    .file-container {
      background-color: transparent !important;
      border: none !important;
      // cursor: pointer;
      // margin-bottom: 5px 20px;
      // display: flex;
      // align-items: center;
      // .file-number {
      //   margin-right: 5px;
      // }
      // svg {
      //   height: 25px !important;
      //   width: 25px !important;
      //   margin-right: 10px;
      // }
      // padding: 5px 10px;
      // border-bottom: 1px solid black;
      // .name {
      //   margin: 0;
      //   font-size: 12px;
      // }
      // .size {
      //   margin: 0;
      //   font-size: 10px;
      // }

      // &:hover {
      //   background-color: #e9f7f0;
      // }
      // &:active,
      // &:focus {
      //   background-color: #b9cfc4;
      // }
    }
  }
}

.lone {
  background-color: #11a945;
}

.icon {
  svg {
    width: 17px !important;
    margin-left: 6px !important;
  }
}

.requests-list-STCS {
  @include appBorderRadius(0px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 5px 24px;
  margin-bottom: 10px;
  // flex-wrap: wrap;

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    span {
      color: #4a4f60;
      display: inline-block;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }

  &--tabsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__tab {
      font-weight: 500;
      font-family: Inter;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      padding-bottom: 3px !important;
      &--text {
        font-size: 1rem;
        font-weight: 500;
      }

      &--count {
        min-width: 14px;
        min-height: 16px;
        padding: 0 5px;
        font-weight: 400;
        font-size: 0.8rem;
        background-color: #edebf4;
        color: #5d6375;
        margin-left: 10px;
        vertical-align: middle;
        @include appBorderRadius(13px);
      }

      &.active {
        font-weight: 700;
        color: #006938 !important;
        border-bottom: 2px solid #006938;
      }

      &.INACTIVE-tab {
        .requests-list-STCS--tabsContainer__tab--text {
          color: #f9554b !important;
        }
        border-color: #f9554b !important;
      }
      &.PENDING-tab {
        .requests-list-STCS--tabsContainer__tab--text {
          color: #f89131 !important;
        }
        border-color: #f89131 !important;
      }
      &.ACTIVE-tab {
        .requests-list-STCS--tabsContainer__tab--text {
          color: #10a925 !important;
        }
        border-color: #10a925 !important;
      }
      &.notDeleted-tab {
        .requests-list-STCS--tabsContainer__tab--text {
          color: #10a925 !important;
        }
        border-color: #10a925 !important;
      }
      &.deleted-tab {
        .requests-list-STCS--tabsContainer__tab--text {
          color: #f9554b !important;
        }
        border-color: #f9554b !important;
      }
    }
  }
  &--columnSelector {
    margin-left: auto;
  }
}

.deleted-reason {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-cells {
  // color: #1A355E;
  color: var(--text-sub-500, #525866);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.table-date {
  color: #464f60;
  background: #e9edf5;
  padding-inline: 8px;
  padding-bottom: 2px;
  border-radius: 4px;
}

.table_dropdown {
  color: var(--Gray-700, #464f60);
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  letter-spacing: 0.36px;
  margin-left: 10px;
}


.comman-span {
  background: #dadfdf;
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 50px;
}
.active-span-1 {
  color: #11a945;
  font-size: 16px;
  .inner-span {
    @extend .comman-span;
  }
}
.deleted-span-1 {
  color: #eb5757;
  font-size: 16px;
  .inner-span {
    @extend .comman-span;
  }
}

:where(.css-dev-only-do-not-override-14mobv6).ant-segmented
  .ant-segmented-item {
  padding-block: 6px;
}

.ant-segmented-group {
  .ant-segmented-item:first-of-type.ant-segmented-item {
    border-bottom: 5px solid #f8f8f8;
  }

  .ant-segmented-item:last-of-type.ant-segmented-item {
    border-bottom: 5px solid #f8f8f8;
  }

  .ant-segmented-item:first-of-type.ant-segmented-item-selected {
    border-bottom: 5px solid #1a8451;
  }

  .ant-segmented-item:last-of-type.ant-segmented-item-selected {
    border-bottom: 5px solid #eb5757;
  }
}
