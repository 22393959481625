$env: 1;
@import "./base/bootstrap.scss";
@import "./base/bootstrap-extended.scss";
@import "./base/colors.scss";
@import "./base/components.scss";

// Themes
@import "./base/themes/dark-layout.scss";
@import "./base/themes/bordered-layout.scss";
@import "./base/themes/semi-dark-layout.scss";
// @import './base/custom-rtl.scss';

// React Specific
@import "react/index";

@import "../../views/wasfaty/style.scss";

.remarks-show {
  margin-bottom: 0;
  color: #22801e;
}
