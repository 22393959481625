$env: 1;
#regional_dashboard {
  background: #f1f5fb;
  border-radius: 8px;
  padding-inline: 20px;
  padding-block: 20px;

  .main_filter_bar {
    display: flex;
    justify-content: space-between;
    align-items: start;
    .filter_bar {
      width: 95%;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      margin-bottom: 20px;

      margin-bottom: 16px;
      .icon {
        height: 48px;
        width: 48px;
        border-radius: 100px;
        background-color: #fff;
        margin-inline: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 6px;
      }

      .filter_collapse {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        font-size: 18px;
        font-weight: 600;
      }

      .filter_collapse1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
      }

      .selected_info {
        display: flex;
        gap: 0;
        margin-right: 10px;
        justify-content: start;
      }

      .selected_item {
        background-color: #f6f8fa;
        padding-top: 3px;
        padding-bottom: 4px;
        padding-inline: 7px;
        margin-left: 10px;
        border-radius: 8px;
        font-size: 14px;
        color: #1890ff;
        font-weight: 500;
      }

      .close_filter {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: #6e6b7b;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        padding-block: 2px;
        .icon_add {
          margin-right: 5px;
        }
      }

      .filter_main {
        background: #f6f8fa;
        height: 100%;
        padding: 6px;
        display: flex;
        justify-content: center;
        border-right: 1px solid #d9d9d9;
        align-items: center;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .filter_main1 {
        background: #f6f8fa;
        height: 100%;
        padding: 6px;
        display: flex;
        justify-content: center;
        border-right: 1px solid #d9d9d9;
        align-items: center;
        border-top-left-radius: 8px;
      }

      .inner_dev {
        padding: 10px 20px;
      }

      .filter_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left_filters {
          min-width: 50%;
          display: flex;
          gap: 10px;
          .filter_options {
            display: flex;
            gap: 10px;

            .all_tag {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px 10px;
              border-radius: 8px;
              font-size: 14px;
              background-color: #f5f5f5;
              border: 1px solid #d9d9d9;
              gap: 10px;
            }
          }
          .dates {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search_box {
              display: flex;
              align-items: center;
              border-radius: var(--Large, 6px);
              background: var(--bg-white-0, #fff);

              .dropdown {
                border: 1px solid var(--stroke-soft-200, #e2e4e9);
                padding-block: 8px;
                background: #f6f8fa;
                border-radius: 6px 0px 0px 6px;
                cursor: pointer;
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                .dropdown_icon {
                  margin-left: 6px;
                }
                .dropdown_val {
                  padding-inline: 8px;
                  color: var(--Gray-700, #464f60);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.28px;
                }
                .chevron {
                  margin-right: 6px;
                }
              }

              .search_input {
                width: 280px;
                padding-right: 30px;
              }

              .searchIcon {
                margin-left: -30px;
                cursor: pointer;
                padding: 6px;
              }

              .filter_date {
                margin-right: 0px;
                position: relative;
                .range_picker {
                  min-width: 280px !important;
                  padding: 10px;
                  background: #f4f5f6 !important;
                  border-radius: 0px 6px 6px 0px;
                  height: 38px;
                  border: 1px solid #e2e4e9;
                }

                figure {
                  position: absolute;
                  top: 9px;
                  right: 10px;
                }
              }

              .form-control {
                padding: 0.581rem 1rem;
                margin: -0.5px !important;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
              }
            }

            .plus_btn {
              display: flex;
              padding: 8px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              gap: 6px;
              border-radius: var(--radius-8, 8px);
              border: 1px solid var(--stroke-soft-200, #e2e4e9);
              background: var(--bg-white-0, #fff) !important;
              box-shadow: 0px 1px 2px 0px rgba(82, 88, 102, 0.06);
            }

            .cross_icon {
              cursor: pointer;
              width: 60px !important;
            }
          }
        }
      }

      .clear_all {
        background-color: transparent;
        color: #1890ff;
        border: none;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }

      .filter_section {
        .filter_title {
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          padding: 12px 0;
        }

        .filter_options {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .selected_tags {
            background-color: green !important;
            color: white;
            border: 1px solid green;
          }

          .unselected_tags {
            cursor: pointer;
          }

          .ant_tag {
            padding: 4px 8px;
            border-radius: 8px;
            font-size: 14px;
            cursor: pointer;
          }

          .close_icon {
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        height: 40px;
        width: 40px;
        border-radius: 100px;
        background-color: #fff;
        margin-inline: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 6px;
      }
    }
  }

  .percentage_card {
    margin-bottom: 20px;

    padding: 12px 12px 12px 29px;
    border-radius: 20px;

    .region_name {
      color: #000000;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
    .hr_row {
      border: 1px solid var(--stroke-soft-200, #e2e4e9);
      margin-block: 10px;
    }

    .circle_box {
      position: fixed;
      height: 50px;
      width: 380px;
      margin-left: 50px;
      margin-top: 30px;

      .left {
        .inner_circle_box {
          width: 170px;
          .percentage {
            color: #000;
            font-size: 40px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: -10px;
          }
          .sites {
            color: var(--text-sub-500, #525866);
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.1px;
          }
        }
        .compiled {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 76px;
          height: 23px;
          flex-shrink: 0;
          border-radius: 40px;
          background: rgba(56, 199, 147, 0.1);
          color: #1e8560;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 17px; /* 141.667% */
        }
      }
      .right {
        .percentage {
          color: #ee0004;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .days {
          color: var(--text-soft-400, #868c98);
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px; /* 240% */
        }
      }
    }
  }

  .number_card {
    background-color: #f1f5fb;
    .no_of_sites {
      position: relative;
      width: 271px;
      height: 129px;
      .arrow {
        position: absolute;
        right: 0px;
      }
      .dashboard_content {
        color: #0b0b0b;
        width: 200px;
        position: absolute;
        left: 24px;
        top: 16px;
        z-index: 10;
        .sites {
          font-size: 10px;
          font-weight: 500;
          line-height: 11.82px;
          margin-top: 10px;
        }
        .count {
          font-size: 42px;
          font-weight: 300;
          line-height: 49.64px;
        }
      }
    }

    .no_of_complaint {
      position: relative;
      width: 271px;
      height: 129px;
      .arrow {
        position: absolute;
        right: 0px;
      }
      .dashboard_content {
        color: #0b0b0b;
        position: absolute;
        width: 200px;
        position: absolute;
        left: 24px;
        top: 16px;
        z-index: 10;
        .complaints {
          font-size: 10px;
          font-weight: 500;
          line-height: 11.82px;
          margin-top: 10px;
        }
        .count {
          font-size: 42px;
          font-weight: 300;
          line-height: 49.64px;
        }
      }
    }
  }

  .region_compliance_card {
    margin-top: -7px;
    padding: 12px 12px 12px 20px;
    border-radius: 20px;

    height: 400px;

    .region_name {
      color: #000000;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
    .hr_row {
      border: 1px solid var(--stroke-soft-200, #e2e4e9);
      margin-block: 10px;
    }
    .sites_name {
      color: #0a0d14;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
    }

    .reset_button {
      margin-right: 30px;
      font-weight: 600;
      font-size: 12px;
      margin-top: 2px;
      color: #375dfb;
      cursor: pointer;
    }
    .progress_bar_container {
      cursor: pointer;
      position: relative;
      .percentage {
        position: absolute;
        z-index: 20;
        left: 28px;
        top: 8px;
        line-height: 17px;
        .p_percentage {
          font-size: 24px;
          font-weight: 400;
        }
        .p_text {
          font-size: 9px;
          font-weight: 300;
          line-height: 17px;
        }
      }
    }

    progress {
      --w: 250px; /* The width */
      /* Set up the progress bar */
      direction: rtl;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      width: var(--w);
      height: 45px;
      margin: 0 10px;
      border-radius: 10em;
      overflow: hidden;
      background-color: var(
        --background-color
      ); /* Dynamically set background color */
    }

    /* Progress bar track background */
    ::-webkit-progress-bar {
      border-radius: 10em;
      background-color: var(
        --background-color
      ); /* Dynamically set background color */
    }

    /* Progress bar fill for Webkit-based browsers */
    ::-webkit-progress-value {
      border-radius: 10em;
      background: var(--lines-color);
      background-image: repeating-linear-gradient(
        45deg,
        var(--background-color),
        var(--background-color) 3px,
        transparent 3px,
        transparent 7px
      );
    }

    /* Progress bar fill for Firefox */
    ::-moz-progress-bar {
      border-radius: 10em;
      background: var(--lines-color);
      background-image: repeating-linear-gradient(
        45deg,
        var(--background-color),
        var(--background-color) 3px,
        transparent 3px,
        transparent 7px
      );
    }
  }

  .table_card {
    margin-bottom: 20px;
    border-radius: 20px;

    .table_item {
      padding-inline: 24px;
      padding-top: 12px;
      padding-bottom: 20px;
      color: var(--text-main-900, #0a0d14);
      line-height: normal;
      .count {
        font-size: 32px;
        font-weight: 400;
      }
      // .icon {
      //   margin-top: -2;
      // }
      .name {
        font-size: 10px;
        font-weight: 400;
      }
    }

    .v_row {
      height: 32px;
      border: 1px solid #e1e1e1;
    }

    .rows_card {
      border-radius: 20px;
      background: var(--green-lighter, #effaf6);
      padding: 24px;

      .card_item {
        .name {
          margin-left: 10px;
          color: var(--neutral-500, #525866);
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
        }
        .count {
          color: var(--green-darker, #176448);
          font-size: 16px;
          line-height: 17px;
          font-weight: 400;
        }
      }
      .h_row {
        width: 100%;
        border: 0.2px solid var(--green-base, #38c793);
      }
    }
  }

  .map_card {
    // margin-bottom: 20px;
    margin-top: 24px;
    border-radius: 20px;

    // padding: 24px;
    height: 400px;
  }

  .cards_group {
    .sites_card {
      border-radius: 20px;
      max-height: 490px;
      padding-block: 22px;
      padding-inline: 16px;
      .title {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 16px;
      }
      .sub_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          cursor: pointer;
        }
        .reset_button {
          margin-right: 30px;
          font-weight: 600;
          font-size: 12px;
          margin-bottom: 16px;
          color: #375dfb;
          cursor: pointer;
        }
      }
      .sub_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--bg-soft-200, #e2e4e9);

        .col_name {
          color: var(--text-main-900, #0a0d14);
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
        }
      }

      .row {
        display: flex;
        padding: 8px 10px;
        justify-content: space-between;
        align-items: center;
        .row_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding-block: 7px;
          .row_val {
            color: var(--text-main-900, #0a0d14);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 250px;
          }
        }
        .h_row {
          width: 100%;
          border: 1px solid var(--stroke-soft-200, #e2e4e9);
        }
      }
    }

    .standerds_card {
      border-radius: 20px;
      max-height: 550px;
      padding-block: 22px;
      padding-inline: 16px;
      .title {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 16px;
      }
      .sub_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--bg-soft-200, #e2e4e9);

        .col_name {
          color: var(--text-main-900, #0a0d14);
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
        }
      }

      .row {
        display: flex;
        padding: 8px 10px;
        justify-content: space-between;
        align-items: center;
        .not_found {
          display: flex;
          justify-content: center;
        }
        .row_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-block: 7px;
          .row_val {
            color: var(--text-main-900, #0a0d14);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 250px;
            // cursor: pointer;
            margin-right: 10px;
          }
          .risk_level {
            display: flex;
            padding: 1px 8px 1px 4px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 6px;
            .text {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
            }
          }
        }
        .h_row {
          width: 100%;
          border: 1px solid var(--stroke-soft-200, #e2e4e9);
        }
      }
    }

    .common_notes_card {
      border-radius: 20px;
      max-height: 520px;
      padding-block: 22px;
      padding-inline: 16px;
      .title {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 16px;
      }
      .sub_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--bg-soft-200, #e2e4e9);

        .col_name {
          color: var(--text-main-900, #0a0d14);
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
        }
      }

      .row {
        display: flex;
        padding: 8px 10px;
        justify-content: space-between;
        align-items: center;
        .not_found {
          display: flex;
          justify-content: center;
        }
        .row_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-block: 7px;
          .row_val {
            color: var(--text-main-900, #0a0d14);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 250px;
            // cursor: pointer;
          }
        }
        .h_row {
          width: 100%;
          border: 1px solid var(--stroke-soft-200, #e2e4e9);
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-amq5gd).ant-tooltip {
  --antd-arrow-background-color: #fff;
}
